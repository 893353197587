import React from 'react'

const ResumeItem3 = props => (
  <div className="ResumeItem3">
    <div className="MyRole">{props.MyRole}</div>
    <div className="Workplace">{props.Workplace}</div>
  </div>
)

export default ResumeItem3
