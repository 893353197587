import React from 'react'

const HText = props => (
  <div className="HText1">
    <div className="Header1">{props.Header1}</div>
    <div className="Text1">{props.Text1}</div>
  </div>
)

export default HText
